<template>
  <div class="admin">

    <div class="admin-area">

      <div class="row row-gutter-20">
        <div class="col-6">
          <admin-nav></admin-nav>
        </div>
        <div class="col-18">

          <header class="admin-header cf">
            <ul class="nav nav-header cf">
              <li><router-link to="/admin/vehicles">Fahrzeuge</router-link></li>
              <li><router-link :to="'/admin/vehicles/'+this.$route.params.id">{{ vehicle.license_plate }}</router-link></li>
              <li><router-link :to="'/admin/vehicles/'+this.$route.params.id+'/damages'">Schäden</router-link></li>
              <li>Schaden verwalten</li>
            </ul>
            <div class="header-actions">
            </div>
          </header>

          <ul class="nav nav-tabs cf">
            <li><router-link :to="'/admin/vehicles/'+this.$route.params.id">Stammdaten</router-link></li>
            <li><router-link :to="'/admin/vehicles/'+this.$route.params.id+'/reservations'">Reservierungen</router-link></li>
            <li class="active"><router-link :to="'/admin/vehicles/'+this.$route.params.id+'/damages'">Schäden</router-link></li>
            <li><router-link :to="'/admin/vehicles/'+this.$route.params.id+'/repairs'">Reparaturen</router-link></li>
            <!--<li><router-link :to="'/admin/vehicles/'+this.$route.params.id+'/insurance'">Versicherung</router-link></li>-->
            <li><router-link :to="'/admin/vehicles/'+this.$route.params.id+'/documents'">Dokumente</router-link></li>
          </ul>

          <div class="admin-body">

            <form @submit="update_damage" class="form" method="post">



              <div class="form-wrap select-wrap">
                <label for="status">Status</label>
                <select v-model="vehicle_damage.status" class="select-wrap">
                  <option value="enabled">Sichtbar</option>
                  <option value="disabled">Unsichtbar</option>
                </select>
              </div>


              <p>
                <input type="submit" value="Speichern ›" class="button button-orange button-small">
              </p>

            </form>

          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import AdminNav from '../../../../components/admin_nav'

export default {
  name: 'new_admin_vehicle_repair',
  components: {
    AdminNav
  },
  data () {
    return {
      vehicle: {},
      vehicle_damage: {}
    }
  },
  methods: {
    get_data() {

      axios.get(process.env.VUE_APP_BASE_API+'/v1/admin/vehicles/'+this.$route.params.id, { headers: { Authorization: this.$store.getters.getToken }})
      .then(response => {
        console.log(response);
        this.vehicle = response.data.vehicle;
      })

      axios.get(process.env.VUE_APP_BASE_API+'/v1/admin/vehicles/'+this.$route.params.id+'/damages/'+this.$route.params.damage_id, { headers: { Authorization: this.$store.getters.getToken }})
      .then(response => {
        console.log(response);
        this.vehicle_damage = response.data.vehicle_damage;
      })

    },
    update_damage: function (e) {
      e.preventDefault();

      axios.patch(process.env.VUE_APP_BASE_API+'/v1/admin/vehicles/'+this.$route.params.id+'/damages/'+this.$route.params.damage_id, {
        'status': this.vehicle_damage.status
      }, { headers: { Authorization: this.$store.getters.getToken }})
      .then(response => {
        console.log(response);
        this.$notify({
          group: 'alert',
          type: 'success',
          title: 'Erfolgreich gespeichert',
        });
        this.get_data();
      })
      .catch(error => {
        error.response.data.errors.forEach(val => {
          this.$notify({
            group: 'alert',
            type: 'error',
            title: val,
          });
        })
      })

    },
  },
  mounted () {
    this.get_data();
  }
}
</script>

<style lang="scss">

</style>
